<template>
	<header>
		<Logo maxwidth="235px" />
	</header>
</template>

<script>
import Logo from '@components/Logo';

export default {
	name: 'HeaderComponetEBRADI',
	components: {
		Logo
	}
}
</script>

<style lang="scss" scoped>
header {
	width: 100%;
	height: 116px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--primary-950);
	border-radius: 0px 0px 20px 20px;
	margin-bottom: 56px;
}

@media (max-width: 900px) {
	header > img {
		display: none !important;
	}
}
</style>
